import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  useGetOccurrenceOnAttendance,
  usePostIntervention,
} from 'services/attendance'
import {
  useModal,
  useFilter,
} from 'domains/occurrence/screens/Attendance/context'
import { Button, ComboboxItem, Icon, Modal } from 'components'
import { Combobox } from 'components/ComboboxV2/Combobox'
import { parseDataToComboboxV2 } from 'utilities/combobox'
import { RouteParams } from 'domains/occurrence/screens/Attendance/types'
import {
  ContactType,
  PartitionOnAttendance,
  Phone,
} from 'services/attendance/types'
import { useUserInfo } from 'shared/hooks'
import { usePhoneCall } from 'services/ura/call'

import styles from './styles.module.scss'
import { handleFormatPhone } from 'domains/occurrence/screens/Attendance/utils'

type PhoneCall = {
  phone: Phone
  contact: {
    id: string
    name: string
  }
}

const handlePhoneType = (type: ContactType) => {
  const options: Record<ContactType, string> = {
    ADMIN: 'Administrador',
    CONTACT: 'Contato',
    GUEST: 'Convidado',
  }

  return options[type]
}

const ActionPlan: FC = () => {
  const { occurrenceId } = useParams<RouteParams>()

  const { filter } = useFilter()
  const { handleCloseModal } = useModal()

  const [searchValue, setSearchValue] = useState('')
  const [selectedPartition, setSelectedPartition] =
    useState<PartitionOnAttendance>()

  const { data, isLoading, isError } =
    useGetOccurrenceOnAttendance(occurrenceId)

  const comboBoxItems = useMemo(
    () =>
      parseDataToComboboxV2(
        data?.partitions.filter((partition) =>
          partition.name.toLowerCase().includes(searchValue.toLowerCase()),
        ) || [],
        'name',
      ),
    [data, searchValue],
  )

  const actionPlan = useMemo(() => {
    const currentPartition = data?.partitions.filter(
      (partition) => partition.id === selectedPartition?.id,
    )

    return currentPartition?.[0]?.actionPlan
  }, [data, selectedPartition])

  const { userInfo } = useUserInfo()
  const { mutate: call, isPending: callIsLoading } = usePhoneCall()
  const { mutate: createIntervention, isPending: createInterventionIsLoading } =
    usePostIntervention(occurrenceId)

  const handlePhoneCall = useCallback(
    ({ phone, contact }: PhoneCall) => {
      const userId = userInfo.id

      if (userId && userInfo) {
        const phoneFormatted = `${phone.provinceCode || ''}${phone.phone}`

        createIntervention(
          {
            userId,
            typeName: 'PHONE_CALL',
            attributes: {
              number: phoneFormatted,
              status: 'PENDING',
              contactName: contact.name,
            },
            title: `Ligação para contato ${contact.name}`,
            tags: [{ name: 'ligacaoContato' }],
          },
          {
            onSuccess: () => {
              handleCloseModal()
              call({
                phoneNumber: phoneFormatted,
                ramal: userInfo?.phoneExtension,
              })
            },
          },
        )
      }
    },
    [call, createIntervention, handleCloseModal, userInfo],
  )

  useEffect(() => {
    setSelectedPartition(data?.partitions[0])
  }, [data, filter])

  return (
    <Modal
      isVisible
      onClose={handleCloseModal}
      title="Selecione um contato"
      size="sm"
    >
      <div className={styles.container}>
        <Combobox
          label={{
            text: 'Partição',
          }}
          isError={isError}
          items={comboBoxItems}
          isLoading={isLoading}
          value={selectedPartition?.name || ''}
          onSearch={setSearchValue}
          onChange={(selected) => {
            const selectedPartition =
              selected as ComboboxItem<PartitionOnAttendance>

            setSelectedPartition(selectedPartition.value)
          }}
        />
        <div className={styles.content}>
          <span>Plano de ação</span>
          <ul>
            {actionPlan?.map((actionPlan) => (
              <li key={actionPlan.id}>
                <div>
                  <span>{actionPlan.name}</span>
                  <span>
                    {handlePhoneType(actionPlan.type)}
                    {actionPlan.hasRemoteControl && <Icon name="control" />}
                  </span>
                </div>
                <span
                  className={styles.phone}
                  onClick={() => {
                    if (!createInterventionIsLoading && !callIsLoading) {
                      handlePhoneCall({
                        phone: {
                          phone: String(actionPlan.phone.number),
                          provinceCode: String(actionPlan.phone.provinceCode),
                        },
                        contact: { id: actionPlan.id, name: actionPlan.name },
                      })
                    }
                  }}
                >
                  <Icon name="phone" />
                  {`(${actionPlan.phone.provinceCode}) ${handleFormatPhone(
                    actionPlan.phone.number.toString(),
                  )}`}
                </span>
              </li>
            ))}
          </ul>
        </div>
        <Button
          type="secondary"
          buttonTitle="Cancelar"
          width="172px"
          onClick={handleCloseModal}
        />
      </div>
    </Modal>
  )
}

export default ActionPlan
