import { FC, useCallback, useState } from 'react'

import { Button, Icon } from 'components'
import { useUserInfo } from 'shared/hooks'
import {
  handleFormatPhone,
  interventionTagLabel,
} from 'domains/occurrence/screens/Attendance/utils'
import { usePostIntervention } from 'services/attendance'
import { Phone, OccurrenceOnAttendance } from 'services/attendance/types'
import { InterventionTag } from 'domains/occurrence/screens/Attendance/types'
import { useModal } from 'domains/occurrence/screens/Attendance/context'

import styles from './styles.module.scss'
import { usePhoneCall } from 'services/ura/call'

type PhonesType = keyof OccurrenceOnAttendance['phones'] | 'actionPlan'

type PhoneCall = {
  phone: Phone
  tag: InterventionTag
  type: PhonesType
}

interface PhonesProps {
  occurrenceId: string
  phones: OccurrenceOnAttendance['phones'] & { actionPlan?: Phone }
}

const callOptions: Record<PhonesType, string> = {
  agent: 'Ligar para o tático',
  module: 'Ligar para central',
  patrimony: 'Ligar para o local',
  police: '',
  actionPlan: 'Ligar para plano de ação',
}

const Phones: FC<PhonesProps> = ({ occurrenceId, phones }) => {
  const { userInfo } = useUserInfo()
  const { mutate: call, isPending: callIsLoading } = usePhoneCall()
  const { handleOpenModal } = useModal()
  const { mutate: createIntervention, isPending } =
    usePostIntervention(occurrenceId)

  const [visible, setVisible] = useState(false)

  const handlePhoneCall = useCallback(
    ({ phone, tag, type }: PhoneCall) => {
      const userId = userInfo.id

      const phoneFormatted = `${phone.provinceCode || ''}${phone.phone}`
      const phoneTitle = `${
        phone.provinceCode ? `(${phone.provinceCode})` : ''
      } ${handleFormatPhone(phone.phone)}`

      const handleTitle = () => {
        const options: Record<PhonesType, string> = {
          actionPlan: 'Ligação para o plano de ação',
          agent: `Ligação para o tático - ${phoneTitle}`,
          module: `Ligação para módulo - ${phoneFormatted}`,
          police: `Ligação para a polícia - ${phoneFormatted}`,
          patrimony: `Ligação para o patrimônio - ${phoneTitle}`,
        }

        return options[type]
      }

      if (userId && userInfo) {
        const tags = []

        tags.push({
          name: interventionTagLabel[tag],
        })

        createIntervention(
          {
            userId,
            typeName: 'PHONE_CALL',
            attributes: {
              number: phoneFormatted,
              status: 'PENDING',
            },
            title: handleTitle(),
            tags,
          },
          {
            onSuccess: () => {
              setVisible(false)
              call({
                phoneNumber: phoneFormatted,
                ramal: userInfo?.phoneExtension,
              })
            },
          },
        )
      }
    },
    [call, createIntervention, userInfo],
  )

  const handleCall = useCallback(
    (type: PhonesType) => {
      const tags: Record<PhonesType, InterventionTag> = {
        agent: 'SAFE_CALL',
        module: 'SAFE_CALL',
        patrimony: 'SAFE_CALL',
        police: 'POLICE_CALL',
        actionPlan: 'CONTACT_CALL',
      }

      const currentPhone = phones[type]

      if (type === 'actionPlan') {
        return handleOpenModal('ACTION_PLAN')
      }

      if (currentPhone) {
        handlePhoneCall({
          phone: currentPhone,
          tag: tags[type],
          type,
        })
      }
    },
    [handleOpenModal, handlePhoneCall, phones],
  )

  const handleListOfPhones = useCallback(() => {
    const keys = Object.keys(phones) as Array<PhonesType>

    return keys.map((phone) => {
      return {
        key: phone,
        label: callOptions[phone],
      }
    })
  }, [phones])

  return (
    <div className={styles.container}>
      {visible && (
        <ul className={styles.dropDown} onMouseLeave={() => setVisible(false)}>
          {handleListOfPhones().map(
            (item) =>
              item.label && (
                <li
                  key={item.key}
                  onClick={() =>
                    !callIsLoading && !isPending && handleCall(item.key)
                  }
                >
                  {item.label}
                </li>
              ),
          )}
        </ul>
      )}
      <Button
        type="primary"
        buttonTitle="Ligar"
        onClick={() => setVisible(true)}
      >
        <Icon name="phone" />
      </Button>
      <Button
        type="secondary"
        disabled={!phones?.police?.phone}
        buttonTitle="Ligar para 190"
        onClick={() => handleCall('police')}
      >
        <Icon name="phone" />
      </Button>
    </div>
  )
}

export default Phones
