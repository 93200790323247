import { useCallback, useEffect, useState } from 'react'
import { useModal } from 'domains/occurrence/screens/Attendance/context'

import { Button, Modal } from 'components'
import { useNavigate } from 'react-router-dom'
import { paths } from 'routes'

const FinishedExternally = () => {
  const { handleCloseModal } = useModal()
  const navigate = useNavigate()
  const [currentTime, setCurrentTime] = useState(5)

  const onSubmit = useCallback(() => {
    navigate(paths.occurrence.search)
  }, [navigate])

  useEffect(() => {
    const countDown = setInterval(() => {
      if (currentTime === 0) {
        onSubmit()
      } else {
        setCurrentTime(currentTime - 1)
      }
    }, 1000)

    return () => {
      clearInterval(countDown)
    }
  }, [currentTime, onSubmit])

  return (
    <Modal
      title="Atendimento finalizado"
      isVisible
      onClose={handleCloseModal}
      size="sm"
      simple
    >
      <p>
        Este atendimento foi finalizado. Você será encaminhado para aguardar uma
        nova ocorrência.
      </p>
      <Modal.Footer>
        <Button
          width="172px"
          type="secondary"
          onClick={handleCloseModal}
          buttonTitle="Manter em tela"
        />
        <Button
          width="172px"
          type="primary"
          onClick={onSubmit}
          buttonTitle={`Finalizar ${currentTime}`}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default FinishedExternally
