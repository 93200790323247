import { useLocation, useParams } from 'react-router-dom'
import { useState, useCallback, useEffect } from 'react'

import { ReactComponent as PlayerIcon } from 'assets/svg/player.svg'

import { usePersistentTab } from 'domains/occurrence/hooks'
import {
  useTimer,
  useToast,
  useToggle,
  useTooltipVisibility,
} from 'shared/hooks'
import { useGetServiceOrders } from 'shared/hooks/serviceOrder/useGetServiceOrders'

import { formatDocument } from 'utilities/masks/document'
import {
  Button,
  ButtonV2,
  ProtectedImage,
  Skeleton,
  TabBar,
  Tag,
  Text,
  Tooltip,
} from 'components'
import { PartitionOnAttendance } from 'services/attendance/types'
import { useGetOccurrenceOnAttendance } from 'services/attendance'
import { OccurrenceDetails as OccurrenceDetailsType } from 'services/displacementMap/types'

import styles from './styles.module.scss'
import { RouteParams } from '../../types'
import { PartitionItem, Phones, ChangeState } from './components'
import { occurrenceTypesLabel, handleStateRender } from '../../utils'
import { useModal } from '../../context'
import Icon from 'components/Icon'
import { paths, buildPath } from 'routes'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'

type StyleOccurrence = 'ISN_OCCURRENCE' | 'ISNT_OCCURRENCE'

type Filter = {
  partition: PartitionOnAttendance
}

const OccurrenceDetails: React.FC = () => {
  const location = useLocation()
  const { occurrenceId } = useParams<RouteParams>()

  const readOnly = !!location.state?.readonly

  const { addToast } = useToast()
  const informativeToast = useToggle()
  const { handleOpenModal, handleCloseModal } = useModal()
  const [filter, setFilter] = useState<Filter | null>(null)

  const handleFilter = (data: Filter) => {
    setFilter(data)
  }

  const { data, isFetched } = useGetOccurrenceOnAttendance(occurrenceId)
  const [tabState, setTabState] = useState<'address' | 'partition'>('address')

  const fromOccurrenceManagement =
    readOnly && location.state.from === 'management-occurrence'

  const canEdit =
    (!readOnly || fromOccurrenceManagement) &&
    data?.occurrence.stateName !== 'FINISHED'

  const timer = useTimer({
    initialTime: data?.occurrence?.createdAt ?? new Date().getTime(),
    finalTime: data?.occurrence?.finishedAt,
  })

  const { replaceUrl } = usePersistentTab('service-order-account-tab')

  const { ref, isTooltipVisible, handleMouseEnter, handleMouseLeave } =
    useTooltipVisibility()

  const handleSeeMore = useCallback(
    (path: string) => {
      if (data?.account?.id) {
        replaceUrl(
          buildPath({
            path: paths.account.details + `?tab=${path}`,
            params: {
              accountId: data.account.id,
            },
          }),
        )
      }
    },
    [data],
  )

  const handlePushCFTV = useCallback(() => {
    window.open(
      buildPath({
        path: paths.occurrence.cftv,
        params: { occurrenceId: data?.occurrence.id },
      }),
    )
  }, [data])

  const selectedPartition = filter?.partition || data?.partitions[0]

  const handleFormatAddress = useCallback(
    (address: OccurrenceDetailsType['patrimony']['address']) => {
      return `${address?.street}, ${address?.number} - ${address?.district}, ${address?.city}, ${address?.acronym}`
    },
    [],
  )

  const { data: serviceOrders, status } = useGetServiceOrders(
    {
      accountIds: [data?.account.id || ''],
      status: ['OPEN'],
    },
    Boolean(data?.account.id),
  )

  useEffect(() => {
    if (serviceOrders?.totalElements) {
      informativeToast.show()
    }

    if (status === 'error') {
      addToast({
        type: 'alert',
        message:
          'Não foi possível recuperar as ordens de serviço. Tente novamente',
      })
    }
  }, [addToast, informativeToast, serviceOrders, status])

  useEffect(() => {
    if (data?.occurrence.stateName === 'FINISHED' && !readOnly) {
      handleOpenModal('FINISHED_EXTERNALLY')
    }
  }, [data, handleOpenModal, readOnly])

  const serviceType = data?.patrimony?.serviceType?.name
  const serviceTypeColor = data?.patrimony?.serviceType?.color

  return (
    <main className={styles.container}>
      {
        {
          view: !!data && (
            <div className={styles.mainContent}>
              <header>
                <h1>Ocorrência #{data.occurrence.number}</h1>
                <div className={styles.actions}>
                  {canEdit && <ChangeState />}
                </div>
              </header>
              <OverlayScrollbarsComponent
                options={{
                  scrollbars: {
                    autoHide: 'leave',
                    theme: 'size-sm',
                    autoHideDelay: 0.3,
                  },
                }}
                className={styles.content}
              >
                <div
                  className={[
                    styles.occurrenceInfo,
                    styles[data.occurrence.type.name],
                    styles[data.occurrence.stateName],
                  ].join(' ')}
                >
                  <Text
                    size="xl"
                    ref={ref}
                    weight="medium"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Icon name="warning" width={18} />
                    {[
                      occurrenceTypesLabel[data.occurrence.type.name],
                      data.occurrence?.title,
                    ]
                      .filter(Boolean)
                      .join(' - ')}
                  </Text>
                  {isTooltipVisible && (
                    <Tooltip
                      parentRef={ref}
                      type="informative"
                      isVisible={isTooltipVisible}
                    >
                      {data.occurrence?.title}
                    </Tooltip>
                  )}
                  <div>
                    <span>{timer}</span>
                  </div>
                </div>
                {data.patrimony.image && (
                  <ProtectedImage
                    width="100%"
                    height={140}
                    imageId={data.patrimony.image}
                    onClick={() => {
                      handleOpenModal('GALLERY', {
                        selectedImageId: data.patrimony.image,
                        images: [
                          {
                            id: data.patrimony.image,
                          },
                        ],
                        onClose: handleCloseModal,
                      })
                    }}
                  />
                )}
                <section className={styles.customerInfo}>
                  <h2>{data.customer?.name}</h2>
                  {data.customer?.secondName && (
                    <h3>{data.customer?.secondName}</h3>
                  )}
                  <h4 className={styles.document}>
                    {formatDocument(data.customer.document)}
                  </h4>
                </section>
                <section>
                  <div className={styles.patrimonyInfo}>
                    <h3 className={styles.patrimonyName}>
                      {data.patrimony?.name}
                    </h3>
                    <h4>
                      {data.account?.code} - {data.account.name}
                    </h4>
                    {!!serviceType && (
                      <Tag
                        color="green"
                        {...(serviceTypeColor && {
                          style: {
                            backgroundColor: serviceTypeColor,
                            color: serviceTypeColor,
                          },
                        })}
                        singleColor={!!serviceTypeColor}
                      >
                        {serviceType}
                      </Tag>
                    )}
                  </div>
                  {informativeToast.isVisible && (
                    <div className={styles.toast}>
                      <div className={styles.toastContent}>
                        <Icon name="information" />
                        <p className={styles.toastContentText}>
                          Existem ordens de serviço abertas <br /> para esta
                          conta.
                        </p>
                      </div>
                      <button
                        className={styles.buttonToast}
                        onClick={() => handleSeeMore('history')}
                      >
                        ACESSAR
                      </button>
                    </div>
                  )}
                  <div>
                    {data.account.tags && (
                      <>
                        <Text
                          color="element-medium"
                          size="sm"
                          weight="semibold"
                        >
                          Detalhes da conta
                        </Text>
                        <div className={styles.tags}>
                          {data.account.tags.map((tag) => (
                            <Tag key={tag.id} size="md">
                              {tag.name}
                            </Tag>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                  <ButtonV2
                    onClick={() => handleSeeMore('patrimony')}
                    appearance="tertiary"
                  >
                    <Icon name="open" />
                    Ver conta
                  </ButtonV2>
                </section>

                <div
                  className={[
                    styles.note,
                    data.account.note && styles.hasNote,
                  ].join(' ')}
                >
                  <div>
                    {!!data.account.note && (
                      <Icon
                        name="warning"
                        color="accent-red-default"
                        width={18}
                      />
                    )}
                    <Text
                      size="md"
                      color={
                        data.account.note
                          ? 'accent-red-bold'
                          : 'element-default'
                      }
                    >
                      Anotações
                    </Text>
                  </div>
                  <p>{data.account.note}</p>

                  <ButtonV2
                    appearance="tertiary"
                    color={data.account.note ? 'red' : 'element'}
                    onClick={() =>
                      handleOpenModal('ACCOUNT_NOTES', {
                        accountId: data.account.id,
                        note: data.account.note,
                      })
                    }
                  >
                    Editar
                  </ButtonV2>
                </div>
                <div className={styles.tabs}>
                  <TabBar fullSize size="sm">
                    <TabBar.Tab
                      id="address"
                      label="Endereço"
                      idSelected={tabState}
                      onClick={() => setTabState('address')}
                    >
                      <div>
                        <section>
                          <div>
                            <h4>Endereço</h4>
                            <span>
                              {handleFormatAddress(data.patrimony.address)}
                            </span>
                          </div>
                          {data.patrimony.note && (
                            <div>
                              <h4>Observações</h4>
                              <span>{data.patrimony.note}</span>
                            </div>
                          )}
                          {(data.occurrence.priority ||
                            data.patrimony.serviceType) && (
                            <div className={styles.patrimonyTags}>
                              {!!data.occurrence.priority && (
                                <div>Prioridade {data.occurrence.priority}</div>
                              )}
                            </div>
                          )}
                        </section>
                      </div>
                    </TabBar.Tab>
                    <TabBar.Tab
                      id="partition"
                      label="Partições"
                      idSelected={tabState}
                      onClick={() => setTabState('partition')}
                    >
                      <div className={styles.partition}>
                        <div className={styles.partitionButtons}>
                          {data.partitions?.map((partition) => {
                            const isSelected =
                              selectedPartition?.id === partition.id
                            const isDisabled = filter
                              ? filter.partition.id !== partition.id
                              : false
                            const describe: StyleOccurrence =
                              partition.isInOccurrence
                                ? 'ISN_OCCURRENCE'
                                : 'ISNT_OCCURRENCE'

                            return (
                              <button
                                key={partition.id}
                                className={[
                                  styles.partitionButton,
                                  styles[describe],
                                  isDisabled && styles.isDisabled,
                                  isSelected
                                    ? styles.isSelected
                                    : styles.unSelected,
                                ].join(' ')}
                                onClick={() => handleFilter({ partition })}
                              >
                                {partition.code} - {partition.name}
                                {partition.isInOccurrence}
                              </button>
                            )
                          })}
                        </div>
                        {selectedPartition && (
                          <PartitionItem partition={selectedPartition} />
                        )}
                      </div>
                    </TabBar.Tab>
                  </TabBar>
                </div>
                <hr />
                <section className={styles.imagesSection}>
                  <span>Galeria de fotos</span>
                  <div>
                    {!!data.occurrence.images?.length && (
                      <ul>
                        {data.occurrence.images.map((image) => (
                          <ProtectedImage
                            key={image.id}
                            imageId={image.id}
                            onClick={() => {
                              handleOpenModal('GALLERY', {
                                imageId: image.id,
                                onClose: handleCloseModal,
                                images: data.occurrence.images,
                              })
                            }}
                          />
                        ))}
                      </ul>
                    )}
                  </div>
                  <div>
                    <Button
                      type="tertiary"
                      buttonTitle="Adicionar foto"
                      onClick={() => handleOpenModal('IMAGE_UPLOADER')}
                    >
                      <Icon name="gallery-add" />
                    </Button>
                  </div>
                </section>
                {data.patrimony.hasCFTV &&
                  data.occurrence.type.name !== 'FINISHED' && (
                    <>
                      <hr />
                      <section
                        className={styles.cftvSection}
                        onClick={handlePushCFTV}
                      >
                        <span>CFTV</span>
                        <ProtectedImage
                          imageId={data.patrimony.image}
                          width="100%"
                          height={224}
                        />
                        <button>
                          <PlayerIcon />
                        </button>
                      </section>
                    </>
                  )}
              </OverlayScrollbarsComponent>
              <Phones
                occurrenceId={occurrenceId || ''}
                phones={{
                  ...data.phones,
                  actionPlan: {
                    extensionCode: String(
                      selectedPartition?.actionPlan?.[0]?.phone?.extension,
                    ),
                    phone: String(
                      selectedPartition?.actionPlan?.[0]?.phone?.number,
                    ),
                  },
                }}
              />
            </div>
          ),
          loading: (
            <div className={styles.mainContent}>
              <header>
                <Skeleton width="auto" height={64} />
              </header>
              <div className={styles.content}>
                <Skeleton width="auto" height={16} />
                <Skeleton width="100%" height={140} />
                <section className={styles.customerInfo}>
                  <Skeleton width={290} height={22} />
                  <Skeleton width={114} height={15} />
                </section>

                <Skeleton width="100%" height={200} />
              </div>

              <Skeleton height={76} width="100%" />
            </div>
          ),
          error: (
            <span className={styles.error}>
              Erro ao carregar ocorrência. Tente novamente.
            </span>
          ),
        }[handleStateRender(data, isFetched)]
      }
    </main>
  )
}

export default OccurrenceDetails
