import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { joiResolver } from '@hookform/resolvers/joi'

import {
  useGetOccurrenceHistory,
  useGetOccurrenceOnAttendance,
  usePostIntervention,
  useDisableHabitDeviation,
} from 'services/attendance'
import {
  convertDateToString,
  convertStringToDate,
  isValidDate,
} from 'utilities/datepicker'
import { useDebounce, useToast, useUserInfo } from 'shared/hooks'
import { parseDataToComboboxV2 } from 'utilities/combobox'
import { ButtonV2, Textarea, Checkbox, Datepicker, ModalV2 } from 'components'
import { Combobox, ComboboxItem } from 'components/ComboboxV2/Combobox'
import { RouteParams } from 'domains/occurrence/screens/Attendance/types'
import { useModal } from 'domains/occurrence/screens/Attendance/context'
import { AttendanceSentenceResponse } from 'services/occurrence/types'

import {
  schema,
  handleHoursAndMinutes,
  EVENT_CODES_CAN_DISABLE_HABIT_DEVIATION,
} from './utils'
import styles from './styles.module.scss'
import { FormPayload } from './types'
import { useGetAttendanceSentence } from 'shared/hooks/services/attendanceSentence/useGetAttendanceSentence'
import { paths } from 'routes'

const FinishOccurrence = () => {
  const [sentenceName, setSentenceName] = useState('')
  const { occurrenceId } = useParams<RouteParams>()
  const navigate = useNavigate()
  const { userInfo } = useUserInfo()

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormPayload>({
    mode: 'onChange',
    resolver: joiResolver(schema),
  })

  const { addToast } = useToast()
  const { handleCloseModal } = useModal()
  const { data: history } = useGetOccurrenceHistory(occurrenceId)
  const { mutate: disableHabitDeviation, isPending: disabledHabitIsLoading } =
    useDisableHabitDeviation()
  const { data: occurrence } = useGetOccurrenceOnAttendance(occurrenceId)
  const { mutate: postIntervention, isPending: interventionLoading } =
    usePostIntervention(occurrenceId)

  const canDisableHabitDeviation = useMemo(() => {
    return history?.events?.some((event) =>
      EVENT_CODES_CAN_DISABLE_HABIT_DEVIATION.includes(event.details.code),
    )
  }, [history])

  const handleFinishOccurrence = useCallback(
    (data: FormPayload) => {
      const userId = userInfo.id

      const tags = data.tags
        ? [{ name: 'finalizada ' }, ...data.tags]
        : [{ name: 'finalizada ' }]

      if (userId) {
        postIntervention(
          {
            typeName: 'STATE_CHANGE',
            attributes: {
              stateName: 'FINISHED',
            },
            userId,
            note: data.note,
            title: 'Finalização de ocorrência',
            tags,
          },
          {
            onSuccess: () => {
              addToast({
                message: 'Intervenção realizada com sucesso',
                type: 'success',
              })

              navigate(paths.occurrence.search)
            },
            onError: () => {
              addToast({
                message: 'Erro ao realizar intervenção na ocorrência',
                type: 'alert',
              })
            },
          },
        )
      }
    },
    [navigate, addToast, postIntervention],
  )

  const onSubmit = useCallback(
    async (data: FormPayload) => {
      if (data.postponeUntil) {
        const partitions = occurrence?.partitions.filter(
          (partition) => partition.isInOccurrence,
        )

        if (partitions) {
          const date = new Date(data.date)
          const [hour, minutes] = data.hour.split(':').map(Number)

          date.setHours(hour)
          date.setMinutes(minutes)

          await Promise.all(
            partitions?.map((partition) => {
              disableHabitDeviation({
                partitionId: partition.id,
                scheduledDate: date.getTime(),
              })
            }),
          )
            .then(() => handleFinishOccurrence(data))
            .catch(() => {
              addToast({
                message: 'Erro ao adiar atendimento',
                type: 'alert',
              })
            })
        }
      } else {
        handleFinishOccurrence(data)
      }
    },
    [occurrence, addToast, disableHabitDeviation, handleFinishOccurrence],
  )

  const handleChangeScheduleDateInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      if (isValidDate(value) && value.length > 9) {
        setValue('date', convertStringToDate(value).getTime(), {
          shouldValidate: true,
        })
      }

      return value
    },
    [setValue],
  )

  const handleFilter = useDebounce(setSentenceName)

  const { data: attendanceSentence } = useGetAttendanceSentence({
    interventionType: 'STATE_CHANGE',
    occurrenceTypeId: occurrence?.occurrence.type.id,
    serviceTypeId: occurrence?.patrimony.serviceTypeId,
    name: sentenceName,
  })

  return (
    <ModalV2.Root isOpen onClose={handleCloseModal}>
      <ModalV2.Content className={styles.content} size="md">
        <ModalV2.Title>Finalizar atendimento de ocorrência</ModalV2.Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Combobox
            searchable
            {...register('tags')}
            label={{
              text: 'Tratativa',
            }}
            onSearch={handleFilter}
            placeholder="Selecione uma opção de tratativa"
            items={parseDataToComboboxV2(
              attendanceSentence?.data || [],
              'name',
            )}
            onChange={(selected) => {
              const selectedItem =
                selected as unknown as ComboboxItem<AttendanceSentenceResponse>

              setValue('tags', selectedItem.value.tags, {
                shouldValidate: true,
              })
              setValue('note', selectedItem.value.sentence, {
                shouldValidate: true,
              })
            }}
          />
          <div className={styles.textareaContainer}>
            <Textarea
              rows={5}
              label="Observações"
              maxLength={2000}
              {...register('note')}
              value={watch('note')}
              className={styles.textArea}
              onChange={(event) =>
                setValue('note', event.target.value, { shouldValidate: true })
              }
            />
            {canDisableHabitDeviation && (
              <div className={styles.habitDeviation}>
                <Checkbox
                  id="postponeUntil"
                  label="Adiar atendimento até"
                  {...register('postponeUntil')}
                  checked={!!watch('postponeUntil')}
                  onChange={(event) =>
                    setValue('postponeUntil', event.target.value, {
                      shouldValidate: true,
                    })
                  }
                />
                <Datepicker
                  id="date"
                  {...register('date')}
                  disabled={!watch('postponeUntil')}
                  initialValueInput={
                    watch('date')
                      ? convertDateToString(new Date(watch('date')))
                      : undefined
                  }
                  onChangeInput={handleChangeScheduleDateInput}
                  initialDate={
                    watch('date') ? new Date(watch('date')) : undefined
                  }
                  onChangeDate={(date) =>
                    setValue('date', date?.getTime(), { shouldValidate: true })
                  }
                />
                <span>às</span>
                <Combobox
                  id="hour-input"
                  placeholder="--:--"
                  {...register('hour')}
                  value={watch('hour')}
                  items={handleHoursAndMinutes()}
                  disabled={!watch('postponeUntil')}
                  onChange={(value) =>
                    setValue('hour', value, { shouldValidate: true })
                  }
                />
              </div>
            )}
          </div>
          <ModalV2.Footer>
            <ModalV2.Close asChild>
              <ButtonV2 appearance="secondary">Cancelar</ButtonV2>
            </ModalV2.Close>
            <ButtonV2
              disabled={
                !isValid || disabledHabitIsLoading || interventionLoading
              }
              type="submit"
            >
              Salvar
            </ButtonV2>
          </ModalV2.Footer>
        </form>
      </ModalV2.Content>
    </ModalV2.Root>
  )
}

export default FinishOccurrence
